import { BlipChat } from 'blip-chat-widget';

const { VUE_APP_BLIPCHAT_APP_KEY_SUPORTE } = process.env;
const { VUE_APP_BLIPCHAT_CUSTOM_COMMON_URL } = process.env;

export default class BlipchatService {
  static build(chatData) {
    const blipClient = new BlipChat();
    blipClient
      .withAppKey(VUE_APP_BLIPCHAT_APP_KEY_SUPORTE)
      .withCustomCommonUrl(VUE_APP_BLIPCHAT_CUSTOM_COMMON_URL)
      .withAccount(chatData)
      .build();
    return blipClient;
  }

  static open(selectedSchool, actualPlan = null) {
    const chatData = BlipchatService.buildChatData(selectedSchool, actualPlan);
    const blipClient = BlipchatService.build(chatData);

    blipClient.toogleChat();

    blipClient.withEventHandler(BlipChat.LEAVE_EVENT, function() {
      blipClient.destroy();
    });
  }

  static buildChatData(selectedSchool, actualPlan) {
    return {
      city: BlipchatService.schoolDomain(selectedSchool),
      extras: {
        origem: 'herospark',
        plano: actualPlan?.slug,
        plano_inicio_em: actualPlan?.activated_at,
        school_id: selectedSchool?.id,
        heimdall_url: BlipchatService.customerFindUrl(selectedSchool),
      },
    };
  }

  static schoolDomain(selectedSchool) {
    if (selectedSchool?.domains.length) {
      return selectedSchool.domains[0];
    }

    const mainDomain =
      selectedSchool?.account_type === 'herospark' ? process.env.VUE_APP_HERO_DOMAIN : process.env.VUE_APP_DOMAIN_URL;

    return ['production', 'staging'].includes(process.env.NODE_ENV)
      ? `${selectedSchool?.subdomain}.${mainDomain}`
      : location.host;
  }

  static customerFindUrl(selectedSchool) {
    return encodeURI(
      `${process.env.VUE_APP_HEIMDALL_BASE_URL}/platforms?tab=tab-3&type=uid&filter[uid]=${selectedSchool?.id}`
    );
  }
}
